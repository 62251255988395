<template>
  <div class="about container-lg">
    <h1>Informationen für Verkäufer</h1>
    <div class="row row-eq-height gx-3 mt-5">
      <div class="col-lg-6 mb-3">
        <div class="card shadow h-100">
          <h3 class="card-header">Was kann verkauft werden?</h3>
          <div class="card-body text-start">
            <p>
              <strong>Jeder Verkäufer kann <u>maximal 50 Artikel</u> verkaufen.</strong>
            </p>
            <ul>
              <li>Kinderbekleidung von Gr. 50 bis 188</li>
              <li>Kinderschuhe, Kinderstiefel, Hausschuhe ...</li>
              <li>Spielzeug, Kinderbücher, Schulranzen, Sporttaschen ...</li>
              <li>Kinderwagen, Kindersitze, Laufräder, Fahrräder ...</li>
              <li>„Zubehör“ rund ums Kleinkind</li>
              <li>u. v. m.</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-6 mb-3">
        <div class="card shadow h-100">
          <h3 class="card-header">Was wird <u>NICHT</u> verkauft?</h3>
          <div class="card-body text-start">
            <p><strong>Jeder Artikel wird von uns sortiert und geprüft.</strong></p>
            <p>
              <strong
                >Wir behalten uns vor, folgende Artikel vom Verkauf
                auszuschließen:</strong
              >
            </p>
            <ul class="text-start">
              <li>Stoff-, Kuschel-, Plüschtiere, Lauflerngeräte, Spielzeugkleinteile</li>
              <li>verschmutzte Artikel</li>
              <li>defekte Artikel</li>
              <li>zu stark abgenutzte Artikel</li>
              <li>nicht oder falsch beschriftete Artikel</li>
              <li>
                Artikel, die das Maximum von 50 Artikeln pro Verkäufer überschreiten
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-eq-height gx-3">
      <div class="col-lg-6 mb-3">
        <div class="card shadow h-100">
          <h3 class="card-header">Schritt 1: Anmelden</h3>
          <div class="card-body">
            <p class="fw-bolder">
              Wer als Verkäufer an unserer Börse teilnehmen möchte, registriert sich auf
              unserer Seite und hinterlegt seine Adressdaten. Ganz einfach!
            </p>
            <p><strong>Die Anmeldefrist endet am 2. März 2025.</strong></p>
            <p>
              <strong
                >Die Vergabe der Verkäufernummern erfolgt am 3. März 2025 per
                Auslosung.</strong
              >
            </p>
            <p>
              Alle ausgelosten Verkäufer werden von uns per E-Mail über ihre
              Verkäufernummer und weitere Details informiert.
            </p>
            <p>
              Hinweis: Damit möglichst viele Familien die Chance haben, sich bei unserer
              Babybörse als Verkäufer zu registrierren, werden doppelte Anmeldungen /
              Registrierungen, beispielsweise auf den Mann, die Frau, einen Verwandten
              usw., von uns bei Erkennen entfernt / deaktiviert.
            </p>
          </div>
          <div class="card-footer">
            <router-link class="btn btn-primary shadow" :to="{ name: 'UserProfile' }">
              Jetzt Registrieren!
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-lg-6 mb-3">
        <div class="card shadow h-100">
          <h3 class="card-header">Schritt 2: Beschriften</h3>
          <div class="card-body">
            <p>
              Nach der Verlosung erhälst Du von uns eine <strong>Verkäufernummer</strong>.
            </p>
            <p>
              Alle Artikel sind mit der Verkäufernummer zu kennzeichnen. Zur Beschriftung
              der Artikel empfehlen wir Rollpflaster, z.B.
              <strong>Leukosilk 2,5cm x 5m</strong>, da dieser am Besten an den
              Kleidungsstücken haftet.
            </p>
            <p>
              <strong
                >Bitte die Artikel unbedingt nach folgendem Muster beschriften:</strong
              >
            </p>
            <div class="row">
              <div class="col-6">
                <strong>Muster</strong>
                <img
                  alt="Musteraufkleber"
                  class="w-100"
                  style="height: 3.8rem"
                  :src="require('@/assets/img/muster-aufkleber.png')"
                />
              </div>
              <div class="col-6">
                <strong>Beispiel</strong>
                <img
                  alt="Musteraufkleber"
                  class="w-100"
                  :src="require('@/assets/img/beispiel-aufkleber.png')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-eq-height gx-3">
      <div class="col-lg-6 mb-3">
        <div class="card shadow h-100">
          <h3 class="card-header">Schritt 3: Abgeben</h3>
          <div class="card-body">
            <p>
              Bitte alle Artikel in einem mit der Verkäufernummer gekennzeichneten
              Wäschekorb abgeben.
            </p>
            <p>Abgabe der Artikel mit beschriftetem Wäschekorb:</p>
            <p>
              <strong><b-icon-calendar-check /> Freitag, den 14. März 2025</strong>,
            </p>
            <p>
              <strong><b-icon-clock /> von 14:00 Uhr bis 17:30 Uhr</strong>
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 mb-3">
        <div class="card shadow h-100">
          <h3 class="card-header">Schritt 4: Abholen</h3>
          <div class="card-body">
            <p>
              Abholung der nicht verkauften Artikel im Wäschekorb<br />
              und Auszahlung des Verkaufserlöses:
            </p>
            <p>
              <strong><b-icon-calendar-check /> Samstag, den 15. März 2025</strong>,
            </p>
            <p>
              <strong><b-icon-clock /> von 16:00 Uhr bis 17:00 Uhr</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
