<template>
  <div class="besucher container-lg">
    <h1>Informationen für Besucher / Käufer</h1>
    <div class="row row-eq-height gx-3 text-center">
      <div class="col-lg-4 mb-3">
        <div class="card shadow h-100">
          <h3 class="card-header">Wann?</h3>
          <div class="card-body">
            <p>
              <b-icon-calendar-check /> Am Sonnabend, den <strong>15. März 2025</strong>,
            </p>
            <p><b-icon-clock /> von <strong>9:00 Uhr bis 12:00 Uhr</strong></p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 mb-3">
        <div class="card shadow h-100">
          <h3 class="card-header">Wo?</h3>
          <div class="card-body">
            <p><strong>Im Bürgerhaus von Windberge</strong></p>
            <p><strong>Adresse:</strong> 39517 Windberge, Friedhofsweg</p>
          </div>
          <div class="card-footer">
            <a
              href="https://www.google.de/maps/place/Friedhofsweg,+39517+Windberge/@52.5222553,11.7141132,993m/data=!3m2!1e3!4b1!4m13!1m7!3m6!1s0x47af3e3b0fca1657:0xe6a9b6da5709e762!2sFriedhofsweg,+39517+Windberge!3b1!8m2!3d52.5222553!4d11.7163019!3m4!1s0x47af3e3b0fca1657:0xe6a9b6da5709e762!8m2!3d52.5222553!4d11.7163019"
              target="_blank"
              ><b-icon-geo-alt-fill /> zur Karte</a
            >
          </div>
        </div>
      </div>
      <div class="col-lg-4 mb-3">
        <div class="card shadow h-100">
          <h3 class="card-header">Parken?</h3>
          <div class="card-body">
            <p>
              Wir haben ausreichend Parkmöglichkeiten
              <strong>auf der ausgeschilderten Rasenfläche</strong> in Windberge in
              unmittelbarer Nähe der Babybörse.
            </p>
            <p><strong>Adresse:</strong> 39517 Windberge, Dorfstraße</p>
          </div>
          <div class="card-footer">
            <a
              href="https://www.google.de/maps/dir//52.5219732,11.7190892/@52.5219805,11.7170764,248m/data=!3m1!1e3!4m2!4m1!3e0"
              target="_blank"
              ><b-icon-geo-alt-fill /> zur Karte</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
